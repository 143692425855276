<template>
  <div>
    404 Page not found
    <img src="@/assets/404Bizz.svg" alt="404 image" class="default-img" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
